<template>
  <div
    class="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8"
    v-loading="loading"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
        alt="Workflow"
      />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Change password
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 text-left shadow sm:rounded-lg sm:px-10">
        <el-form class="space-y-6" :model="form" :rules="rules" ref="form">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Full Name
            </label>
            <div class="mt-1">
              <input
                id="name"
                name="name"
                type="text"
                v-model="user.FullName"
                autocomplete="name"
                disabled
                readonly
                class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
              />
            </div>
          </div>
          <el-form-item
            label="Temporary Password Code"
            prop="tempPassCode"
            class="block text-sm font-medium text-gray-700"
          >
            <div class="mt-1">
              <el-input
                placeholder="Temporary Password Code"
                v-model="form.tempPassCode"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item
            label="New Password"
            prop="password"
            class="block text-sm font-medium text-gray-700"
          >
            <!-- <label for="email" class="block text-sm font-medium text-gray-700">
              New Password
            </label> -->
            <div class="mt-1">
              <el-input
                placeholder="New password"
                v-model="form.password"
                show-password
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item
            label="Confirm Password"
            class="block text-sm font-medium text-gray-700"
            prop="confirmPassword"
          >
            <!-- <label for="email" class="block text-sm font-medium text-gray-700">
              Confirm Password
            </label> -->
            <div class="mt-1">
              <el-input
                placeholder="Confirm password"
                v-model="form.confirmPassword"
                show-password
              ></el-input>
            </div>
          </el-form-item>
          <div class="flex items-center justify-between">
            <div class="ml-auto text-sm">
              <a
                href="#"
                @click.prevent="goToLogin"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Back to Login
              </a>
            </div>
          </div>
          <el-form-item class="py-2">
            <el-button
              class="focus:outline-none w-full border-none bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500"
              type="primary"
              @click="onSubmit('form')"
              >Change Password</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    let reg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,12}$/;

    var validateNewPwd = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(
          new Error(
            "The password should be combination of numbers, letters or characters!"
          )
        );
      } else {
        callback();
      }
    };
    var validateComfirmPwd = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(
          new Error(
            "The password should be combination of numbers, letters or characters!"
          )
        );
      } else if (this.form.password !== value) {
        callback(
          new Error(
            "The confirmation password is different from the new password."
          )
        );
      } else {
        callback();
      }
    };

    return {
      id: null,
      user: {},
      loading: false,
      form: {
        password: null,
        confirmPassword: null,
        tempPassCode: null,
      },
      rules: {
        password: [
          {
            required: true,
            message: "Please enter a new password.",
          },
          {
            min: 6,
            max: 12,
            message: "Length of 6 to 12 characters",
            trigger: "blur",
          },
          { validator: validateNewPwd, trigger: "blur" },
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please enter a confirmation password.",
          },
          {
            min: 6,
            max: 12,
            message: "Length of 6 to 12 characters",
            trigger: "blur",
          },
          { validator: validateComfirmPwd, trigger: "blur" },
        ],
        tempPassCode: [
          {
            required: true,
            message: "Please enter a Temporary Password Code.",
          },
        ],
      },
    };
  },
  async created() {
    this.loading = true;
    this.id = this.$route.params.id;

    const url =
      process.env.VUE_APP_API_LOCATION +
      `api/SystemUsers/GetPasswordResetRequestEventUser?id=${this.id}`;

    let results = await axios.get(url);
    console.log({ results });

    this.user = results.data.Item;

    this.loading = false;
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const url = `${process.env.VUE_APP_API_LOCATION}api/SystemUsers/changepassword`;
          axios
            .put(url, {
              username: this.user.Username,
              originalPassword: this.form.tempPassCode,
              newPassword: this.form.password,
            })
            .then(() => {
              this.$notify({
                title: "Success",
                message: "Password changed!",
                type: "success",
              });

              this.router.push({ name: "login" });
            })
            .catch((error) => {
              this.$notify({
                title: "Error",
                message: error.Message,
                type: "error",
              });
            });
        } else {
          return false;
        }
      });
    },

    goToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
